import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {
    AppBar,
    AppBarSection,
    AppBarSpacer,
    Avatar,
    Drawer,
    DrawerContent,
    DrawerSelectEvent,
} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';
import * as svgIcons from '@progress/kendo-svg-icons';
import {Badge, BadgeContainer} from "@progress/kendo-react-indicators";
import {SvgIcon} from "@progress/kendo-react-common";
import {bellIcon} from "@progress/kendo-svg-icons";
import {ThemeChooser} from "../common/componnents/themeChooser";

let kendoAvatar = 'https://www.telerik.com/kendo-react-ui-develop/components/images/kendoka-react.png';

const items = [
    {
        text: 'Avatar',
        svgIcon: svgIcons.userIcon,
        selected: true,
        route: '/',
    },
    {
        separator: true,
    },
    {
        text: 'BottomNavigation',
        svgIcon: svgIcons.moreHorizontalIcon,
        route: '/bottomnavigation',
    },
    {
        separator: true,
    },
    {
        text: 'Breadcrumb',
        svgIcon: svgIcons.chevronRightIcon,
        route: '/breadcrumb',
    },
    {
        separator: true,
    },
    {
        text: 'Card',
        svgIcon: svgIcons.imageIcon,
        route: '/card',
    },
    {
        separator: true,
    },
    {
        text: 'GridLayout',
        svgIcon: svgIcons.gridLayoutIcon,
        route: '/gridlayout',
    },
    {
        separator: true,
    },
    {
        text: 'Menu',
        svgIcon: svgIcons.menuIcon,
        route: '/menu',
    },
    {
        separator: true,
    },
    {
        text: 'PanelBar',
        svgIcon: svgIcons.layoutSideBySideIcon,
        route: '/panelbar',
    },
    {
        separator: true,
    },
    {
        text: 'Splitter',
        svgIcon: svgIcons.colResizeIcon,
        route: '/splitter',
    },
    {
        separator: true,
    },
    {
        text: 'StackLayout',
        svgIcon: svgIcons.layoutStackedIcon,
        route: '/stacklayout',
    },
    {
        separator: true,
    },
    {
        text: 'Stepper',
        svgIcon: svgIcons.listOrderedIcon,
        route: '/stepper',
    },
    {
        separator: true,
    },
    {
        text: 'TabStrip',
        svgIcon: svgIcons.thumbnailsUpIcon,
        route: '/tabstrip',
    },
    {
        separator: true,
    },
    {
        text: 'TileLayout',
        svgIcon: svgIcons.layout1By4Icon,
        route: '/tilelayout',
    },
];

const DrawerRouterContainer = (props: any) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const [selected, setSelected] = React.useState(
        items.findIndex((x) => x.selected === true)
    );

    const handleClick = () => {
        setExpanded(!expanded);
    };

    const onSelect = (e: DrawerSelectEvent) => {
        navigate(e.itemTarget.props.route);
        setSelected(e.itemIndex);
    };

    return (
        <>
            <AppBar>
                <AppBarSection>
                    <Button
                        svgIcon={svgIcons.layoutIcon}
                        fillMode="flat"
                        onClick={handleClick}
                    />
                </AppBarSection>

                <AppBarSection>
                    <h1 className="title">AppBar expanding Drawer component</h1>
                </AppBarSection>

                <AppBarSpacer/>

                <AppBarSpacer/>

                <AppBarSection className="actions">
                    <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
                        <BadgeContainer>
                            <SvgIcon icon={bellIcon}/>
                            <Badge
                                shape="dot"
                                themeColor="info"
                                size="small"
                                position="inside"
                            />
                        </BadgeContainer>
                    </button>
                </AppBarSection>

                <AppBarSection>
                    <span className="k-appbar-separator"/>
                </AppBarSection>

                <AppBarSection>
                    <Avatar type="image">
                        <img src={kendoAvatar} alt="KendoReact Layout Kendoka Avatar"/>
                    </Avatar>
                </AppBarSection>

                <AppBarSection>
                    <span className="k-appbar-separator"/>
                </AppBarSection>

                <AppBarSection>
                    <ThemeChooser />
                </AppBarSection>

            </AppBar>

            <Drawer
                expanded={expanded}
                mode="push"
                position="start"
                mini={true}
                items={items.map((item, index) => ({
                    ...item,
                    selected: index === selected,
                }))}
                onSelect={onSelect}
            >
                <DrawerContent>
                    {items.map((item) => {
                        return (
                            item.selected && (
                                <div className="content" id={item.text}>
                                    {props.children}
                                </div>
                            )
                        );
                    })}
                </DrawerContent>
            </Drawer>
            <style>{`
              .header { padding: 20px; text-align: center; }
              .content { padding: 40px 20px; }
              `}</style>
        </>
    );
};

export default DrawerRouterContainer;
