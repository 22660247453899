// themeContext.tsx
import React, { useState } from 'react';

export const ThemeContext = React.createContext({
    theme: 'kendo-theme-default',
    switchTheme: (theme: string) => {}
});

// @ts-ignore
export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('Default');

    const switchTheme = (newTheme: string) => {
        // Set the new theme
        setTheme(newTheme);

        // Create a new link element for the new theme
        const link: any = document.getElementById('telerikTheme');
        debugger;
        if(link) {
            link.rel = 'stylesheet';
            link.id = 'telerikTheme';
            link.href = `https://unpkg.com/@progress/${newTheme}@latest/dist/all.css`;
        }
    };

    return (
        <ThemeContext.Provider value={{ theme, switchTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};