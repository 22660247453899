// App.tsx
import * as React from "react";
import {MainApp} from "./componnents/main";
import {ThemeProvider} from "./common/componnents/themeContext";
// import '@progress/kendo-theme-default/dist/all.css';

export const App = () => {
    return (
        <ThemeProvider>
            <MainApp/>
        </ThemeProvider>
    );
}
// import '@progress/kendo-theme-default/dist/all.css';
// import '@progress/kendo-theme-default/dist/all.css';
// import '@progress/kendo-theme-bootstrap/dist/all.css';
// import '@progress/kendo-theme-fluent/dist/all.css';
// import '@progress/kendo-theme-material/dist/all.css';
// import '@progress/kendo-theme-nouvelle/dist/all.css';
