// themeChooser.tsx
import React from "react";
import {ThemeContext} from "./themeContext";
import {DropDownList} from "@progress/kendo-react-dropdowns";

const themes = [
    'kendo-theme-default',
    'kendo-theme-bootstrap',
    'kendo-theme-fluent',
    'kendo-theme-material',
    'kendo-theme-nouvelle'
];

const themeNames = themes.map(theme => {
    let themeName = theme.replace('kendo-theme-', '');
    return themeName.charAt(0).toUpperCase() + themeName.slice(1);
});

export const ThemeChooser = () => {
    const { theme, switchTheme } = React.useContext(ThemeContext);

    const handleThemeChange = (event: any) => {
        const selectedTheme = `kendo-theme-${event.target.value.toLowerCase()}`;
        switchTheme(selectedTheme);
    };

    return (
        <div>
            <DropDownList
                style={{ width: "100px" }}
                data={themeNames}
                defaultValue={theme.replace('kendo-theme-', '')}
                onChange={handleThemeChange}
            />
        </div>
    );
}